import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Grid from "styled-components-grid";
import { Margin } from "styled-components-spacing";
import Layout from "../components/Layout";
import Header from "../components/GatsbyHeader";
import Heading from "../components/Heading";
import Markdown from "../components/Markdown";
import Section from "../components/Section";
import Content, { HTMLContent } from "../components/Content";

export const OurPeoplePageTemplate = ({
  title,
  content,
  image,
  people,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;
  return (
    <React.Fragment>
      <Header
        imageData={
          image && !!image.childImageSharp ? image.childImageSharp.fluid : image
        }
        title={title}
      />
      <Section>
        {/* <PageContent className="content" content={content} /> */}

        {people &&
          people.map((person, index) => (
            <Margin
              top={index !== 0 && { mobile: 4, tablet: 5 }}
              key={person.name}
            >
              {/* <Card> */}
              <Grid wrap={{ desktop: false }}>
                <Grid.Unit size={{ desktop: 2 / 5 }}>
                  <Margin bottom={3}>
                    <Heading size={3}>{person.name}</Heading>
                  </Margin>
                </Grid.Unit>
                <Grid.Unit size={{ desktop: 3 / 5 }}>
                  {/* <Measure>{person.bio}</Measure> */}
                  <Markdown content={person.bio} />
                </Grid.Unit>
              </Grid>
              {/* </Card> */}
            </Margin>
          ))}
      </Section>
    </React.Fragment>
  );
};

OurPeoplePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const OurPeople = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <OurPeoplePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        people={post.frontmatter.people}
        content={post.html}
      />
    </Layout>
  );
};

OurPeople.propTypes = {
  data: PropTypes.object.isRequired
};

export default OurPeople;

export const OurPeopleQuery = graphql`
  query OurPeople($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        people {
          name
          bio
        }
      }
    }
  }
`;
